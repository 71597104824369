import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/MainBoard.vue"; //主页
import Login from "../views/login/index.vue"; //登录页
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    component: Main,
    name: "Main",
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: () => import("@/views/Home.vue"),
        name: "home",
        meta: ["首页", "首页"],
      },
      {
        path: "/userManage/index",
        component: () => import("@/views/userManage/index.vue"),
        name: "user",
        meta: ["用户管理", "用户列表"],
      },
      {
        path: "/userManage/userDetail",
        name: "userDetail",
        component: () => import("@/views/UserDetail.vue"),
        meta: ["用户管理", "用户详情"],
      },
      {
        path: "/pickManage/index",
        component: () => import("@/views/PickManage/index.vue"),
        name: "pick",
        meta: ["拨片管理", "拨片列表"],
      },
      {
        path: "/drumManage/index",
        component: () => import("@/views/DrumManage/index.vue"),
        name: "drum",
        meta: ["鼓机管理", "鼓机列表"],
      },
      {
        //教学视频
        path: "/tutorialManage/index",
        component: () => import("@/views/TutorialManage/index.vue"),
        name: "tutorial",
        meta: ["教学视频", "教学列表"],
      },
      {
        path: "/logManage/index",
        component: () => import("@/views/LogManage/index.vue"),
        name: "errorLog",
        meta: ["错误日志", "错误列表"],
      },
      {
        path: "/allowListManage/index",
        component: () => import("@/views/AllowListManage/index.vue"),
        name: "whiteBlack",
        meta: ["白名单", "白名单列表"],
      },
      {
        path: "/appManage/index",
        component: () => import("@/views/AppManage/index.vue"),
        name: "appVersion",
        meta: ["APP版本", "APP版本列表"],
      },
      {
        path: "/firmWareManage/index",
        component: () => import("@/views/FirmWareManage/index.vue"),
        name: "versionDevice",
        meta: ["固件版本", "固件版本列表"],
      },
      {
        path: "/settingManage/index",
        component: () => import("@/views/SettingManage/index.vue"),
        name: "commonSetting",
        meta: ["通用配置", "通用配置列表"],
      },
      //   {
      //     path: "/promptManage/index",
      //     component: () => import("@/views/PromptManage/index.vue"),
      //     name: "prompt",
      //     meta: ["提示词", "提示词列表"],
      //   },
      {
        path: "/appUserFeedbackManage/index",
        component: () => import("@/views/AppUserFeedbackManage/index.vue"),
        name: "appWrong",
        meta: ["系统优化", "系统优化列表"],
      },
      {
        path: "/appUserReportManage/index",
        component: () => import("@/views/AppUserReportManage/index.vue"),
        name: "tipOff",
        meta: ["举报", "举报列表"],
      },
      {
        path: "/appBulletinManage/index",
        component: () => import("@/views/AppBulletinManage/index.vue"),
        name: "bulletin",
        meta: ["官方公告", "官方公告列表"],
      },
      {
        path: "/userManage/pretendUser",
        component: () => import("@/views/userManage/pretendUser/index.vue"),
        name: "pretendUser",
        meta: ["马甲用户", "马甲用户"],
      },
      {
        path: "/userManage/pretendUser/pretendUserDetail",
        component: () =>
          import("@/views/userManage/pretendUser/pretendUserDetail/index.vue"),
        name: "pretendUserDetail",
        meta: ["马甲用户", "马甲用户详情"],
      },
      {
        path: "/userManage/pretendUser/pretendSongEdit",
        component: () => import("@/views/SongManage/SongEdit/index.vue"),
        name: "pretendSongCustomEdit",
        meta: ["马甲用户", "马甲歌曲编辑"],
      },
      {
        path: "/songManage/allSong",
        component: () => import("@/views/SongManage/index.vue"),
        name: "song",
        meta: ["歌曲管理", "歌曲管理列表"],
      },
      {
        path: "/songManage/songEdit",
        component: () => import("@/views/SongManage/SongEdit/index.vue"),
        name: "songEdit",
        meta: ["歌曲管理", "歌曲编辑"],
      },
      {
        path: "/songManage/songCategoryEdit",
        component: () =>
          import("@/views/SongManage/SongCategoryEdit/index.vue"),
        name: "songCategoryEdit",
        meta: ["歌曲管理", "歌曲分类管理"],
      },
      {
        path: "/songManage/songError",
        component: () => import("@/views/SongManage/SongError/index.vue"),
        name: "songError",
        meta: ["歌曲管理", "曲谱报错列表"],
      },
      {
        path: "/songManage/songCustom",
        component: () => import("@/views/SongManage/SongCustom/index.vue"),
        name: "customSong",
        meta: ["歌曲管理", "自定义歌曲列表"],
      },
      {
        path: "/songManage/songCustom/addPretendSong",
        component: () => import("@/views/SongManage/SongEdit/index.vue"),
        name: "addPretendSong",
        meta: ["歌曲管理", "马甲歌曲添加"],
      },
      {
        path: "/songManage/songCustom/edit",
        component: () => import("@/views/SongManage/SongCustom/Edit/index.vue"),
        name: "customSongEdit",
        meta: ["歌曲管理", "自定义歌曲编辑"],
      },
      {
        path: "/songManage/tagMusic",
        component: () => import("@/views/SongManage/Tag/tagManage.vue"),
        name: "tagMusic",
        meta: ["歌曲管理", "标签配置"],
      },
      {
        path: "/songManage/tag",
        component: () => import("@/views/SongManage/Tag/index.vue"),
        name: "tagSong",
        meta: ["歌曲管理", "歌曲标签管理"],
      },
      {
        path: "/songManage/unfoundSongs",
        component: () => import("@/views/SongManage/Unfound/index.vue"),
        name: "noSearch",
        meta: ["歌曲管理", "搜不到"],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history", // 使用历史模式
  routes,
});
// 全局导航守卫
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    console.warn(`未匹配的路由: ${to.fullPath}`, from); // 打印当前路径
    next("/home"); // 重定向到首页
  } else {
    next();
  }
});

export default router;
